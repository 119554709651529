import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { product } from '../../Data/productsApi';
import Layout from '../Layout';
import WhatsappButton from '../WhatsappButton';
import './product.css';

const Product = () => {
  const { proID } = useParams();
  let filterProduct = product.find(x => x.id === proID);

  return (
    <Layout>
      <WhatsappButton />
      <div className='container-fluid' id='container'>
        <div className='row'>
          {/* Filter Button for Small Screens */}
          <div className='d-block d-md-none text-start my-3 bg-light'>
          <i class="fa-solid fa-layer-group"></i>
            <button className='btn ' data-bs-toggle='offcanvas' data-bs-target='#categoryOffcanvas'>
              <h4>Categories</h4>
            </button>
          </div>

          {/* Offcanvas for Categories */}
          <div className='offcanvas offcanvas-start' tabIndex='-1' id='categoryOffcanvas'>
            <div className='offcanvas-header'>
              <h5 className='offcanvas-title'>Categories</h5>
              <button type='button' className='btn-close' data-bs-dismiss='offcanvas'></button>
            </div>
            <div className='offcanvas-body'>
              {product.map((ele, index) => (
                <ul key={index} id='ul' style={{ listStyleType: 'none' }}>
                  <li>
                    <Link className='filter-btn' to={`/${ele.title}/${ele.id}`} data-bs-dismiss='offcanvas'>
                      {ele.title}
                    </Link>
                  </li>
                </ul>
              ))}
            </div>
          </div>

          {/* Categories Section (Hidden on Small Screens) */}
          <div className='col-md-3 d-none d-md-block left bg-light'>
            <h1 className='mt-3 p-2'>Categories</h1>
            <div className='mt-4'>
              {product.map((ele, index) => (
                <ul key={index} id='ul' style={{ listStyleType: 'none' }}>
                  <li>
                    <Link className='filter-btn' to={`/${ele.title}/${ele.id}`}>{ele.title}</Link>
                  </li>
                </ul>
              ))}
            </div>
          </div>

          <div className='col-md-9 col-sm-12 right'>
            <h1 className='p-3 ms-4 mb-3 fw-bold' style={{ color: 'rgb(144, 19, 117)' }}>{filterProduct.title}</h1>
            <p className='mx-5' style={{ textAlign: 'justify' }}>{filterProduct.para}</p>
            {filterProduct.images.map((img, index) => (
              <img
                className='my-5 add'
                key={index}
                src={img}
                alt={`Cosmetic ${index + 1}`}
                style={{ width: '310px', height: '300px', marginLeft: '12px' }}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Product;
