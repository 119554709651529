import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import SelectDrop from '../Components/selectDrop/SelectDrop';
import { product} from '../Data/productsApi';
import './Navbar.css'


const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
 
  return (
    <>
    {/* header top start here */}

    <div className="p-3 text-center bg-white border-bottom">
      
    <div className="container">
      <div className="row">
        {/* Left elements */}
        <div className="col-md-3 d-flex justify-content-center justify-content-md-center mb-3 mb-md-0 logo">
          <Link to='/' className="ms-md-">
            <img src="../assests/A3HMIR.png" height={65} alt='logo' />
          </Link>
        </div>
        {/* Left elements */}
        {/* Center elements */}
        <div className="col-md-6 d-none d-lg-flex my-auto">
          <form className="d-flex input-group w-auto  mb-md-0">
          <div className='headerSearch d-flex align-items-center border '>
                  
                       <SelectDrop data= {product.slice(0,5).map((ele, index) => (
                                  <div key={index}    >
                                   <Link to={`/${ele.title}/${ele.id}`} className="filter-btn "> {ele.title}
                                  </Link>
                                  </div>
                                ))} 
                    placeholder='All Categories'
                     icon={false} />
                    
                    <div className='search'>
                      
                      {/* <input type='text' placeholder='Search for items'/> */}
                     <i className="fas fa-angle-down searchicon cursor" style={{fontSize: 24}} />

                    {/* <SearchIcon className='searchicon cursor'/> */}
                    </div>
                    </div>
            {/* <span className="input-group-text border-0 d-none d-lg-flex"><i className="fas fa-search" /></span> */}
          </form>
        </div>
        {/* Center elements */}
        {/* Right elements */}
        <div className="col-md-3 d-none d-lg-flex justify-content-center justify-content-md-end align-items-center mt-">
          <div className="d-flex">
          <ul className="navbar-nav flex-row">
            {/* Icons */}
            
            <li className="nav-item">
            <Link to="https://web.facebook.com/a3hmirglobalcorporation?mibextid=ZbWKwL&_rdc=1&_rdr" target="_blank"> <i class="fa-brands fa-facebook-f p-2 me-2 fs-5" id='icon'></i></Link>
              
            </li>
            <li className="nav-item">
            <Link to="https://www.linkedin.com/company/mirglobalcorporation/" target="_blank"> <i class="fa-brands fa-linkedin-in me-2 fs-5 p-2" id='icon'></i></Link> 
            </li>
            <li className="nav-item">
            <Link to="https://www.instagram.com/a3hmirglobalcorporations/" target="_blank"> <i class="fa-brands fa-instagram-square p-2 me-2 fs-5" id='icon'></i></Link>
            </li>
            <li className="nav-item">
            <Link to="https://web.facebook.com/a3hmirglobalcorporation?mibextid=ZbWKwL&_rdc=1&_rdr" target="_blank"> <i class="fa-brands fa-x-twitter p-2 me-2 fs-5" id='icon'></i></Link>
            </li>
          </ul>
          </div>
        </div>
        {/* Right elements */}
      </div>
    </div>
  </div>
    {/* header top end here */}
<div className='container-fluid'>
<nav className="navbar top-fixed navbar-expand-lg navbar-light  ">
  <div className="container-fluid">
    {/* humburger button */}
    <button className="navbar-toggler bg-light"  onClick={toggleMenu}>
      <span className="navbar-toggler-icon" />
    </button>
    {/* Sidebar Menu (Appears on Small Screens) */}
    <div className={`sidebar ${menuOpen ? "show" : ""}`}>
            <button className="close-btn" onClick={toggleMenu}>&times;</button>
            <ul className="sidebar-nav">
              {/* <li><Link to="/" onClick={toggleMenu}>Home</Link></li> */}
              <li><Link to="/about" onClick={toggleMenu}>About</Link></li>
              <li>
                <Link to="/" onClick={toggleMenu}>Products</Link>
                <ul>
                  {product.slice(0, 5).map((ele, index) => (
                    <li key={index}>
                      <Link to={`/${ele.title}/${ele.id}`} onClick={toggleMenu}>{ele.title}</Link>
                    </li>
                  ))}
                </ul>
              </li>
              <li><Link to="/contact" onClick={toggleMenu}>Contact</Link></li>
              <li><Link to="/job" onClick={toggleMenu}>Career</Link></li>
            <ul className="d-flex justify-content-center social ">
            <li>
              <Link to="https://web.facebook.com/a3hmirglobalcorporation" target="_blank">
                <i className="fa-brands fa-facebook-f p-2 fs-5" id="icon"></i>
              </Link>
            </li>
            <li>
              <Link to="https://www.linkedin.com/company/mirglobalcorporation/" target="_blank">
                <i className="fa-brands fa-linkedin-in p-2 fs-5" id="icon"></i>
              </Link>
            </li>
            <li>
              <Link to="https://www.instagram.com/a3hmirglobalcorporations/" target="_blank">
                <i className="fa-brands fa-instagram-square p-2 fs-5" id="icon"></i>
              </Link>
            </li>
            <li>
              <Link to="https://web.facebook.com/a3hmirglobalcorporation" target="_blank">
                <i className="fa-brands fa-x-twitter p-2 fs-5" id="icon"></i>
              </Link>
            </li>
          </ul>
            </ul>
          </div>

          {/* Social Icons in Sidebar */}
        {/* <div className="sidebar-social">
          <ul className="d-flex justify-content-center">
            <li>
              <Link to="https://web.facebook.com/a3hmirglobalcorporation" target="_blank">
                <i className="fa-brands fa-facebook-f p-2 fs-5" id="icon"></i>
              </Link>
            </li>
            <li>
              <Link to="https://www.linkedin.com/company/mirglobalcorporation/" target="_blank">
                <i className="fa-brands fa-linkedin-in p-2 fs-5" id="icon"></i>
              </Link>
            </li>
            <li>
              <Link to="https://www.instagram.com/a3hmirglobalcorporations/" target="_blank">
                <i className="fa-brands fa-instagram-square p-2 fs-5" id="icon"></i>
              </Link>
            </li>
            <li>
              <Link to="https://web.facebook.com/a3hmirglobalcorporation" target="_blank">
                <i className="fa-brands fa-x-twitter p-2 fs-5" id="icon"></i>
              </Link>
            </li>
          </ul>
        </div> */}
      
 {/* Overlay */}
 <div className={`overlay ${menuOpen ? "show" : ""}`} onClick={toggleMenu}></div>


  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    
    
  <ul className="navbar-nav m-auto   mb-2 mb-lg-0" >
    <li className="nav-item    me-1">
      <Link className="nav-link active fs-5 " to='/' aria-current="page" id='col' >Home</Link>
    </li>
    {/* Vertical Line */}
    <div className="col-md-1 d-md-flex d-none align-items-center justify-content-center" >
            <div className="vertical-line" ></div>
          </div>
    <li className="nav-item   ">
      <Link className="nav-link  fs-5" to='/about' id='col'>About</Link>
    </li>
     {/* Vertical Line */}
     <div className="col-md-1 d-md-flex d-none align-items-center justify-content-center" >
            <div className="vertical-line" ></div>
          </div>
    <li className="nav-item dropdown  ">
      <Link className="nav-link dropdown-toggle   fs-5"  to='/' id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" >
        Products
      </Link>
      <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
      <li><Link className="proitem" to='/garments'>{product.slice(0,5).map((ele, index) => (
                                  <div key={index}    >
                                   <Link to={`/${ele.title}/${ele.id}`} className="filter-btn "> {ele.title}
                                  </Link>
                                  </div>
                                ))} </Link></li>
      </ul>
    </li>
     {/* Vertical Line */}
     <div className="col-md-1 d-md-flex d-none align-items-center justify-content-center" >
            <div className="vertical-line" ></div>
          </div>
    <li className="nav-item ">
      <Link className="nav-link  fs-5 " to='/contact'  id='col' >Contact</Link>
    </li>
     {/* Vertical Line */}
     <div className="col-md-1 d-md-flex d-none align-items-center justify-content-center" >
            <div className="vertical-line" ></div>
          </div>
    <li className="nav-item ">
      <Link className="nav-link  fs-5 " to='/job' id='col'  >Career</Link>
    </li>
    
  </ul> </div>
  <div className=' px-3 text-light book'>
    BOOK YOUR ORDER NOW<br/>
    +971-562388456
  </div>
  
     
    </div>
</nav>
</div>
    </>
  )
}

export default Navbar
