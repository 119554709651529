export const other = [
  { image: "./assests/cosmetics/p1.png" },
  { image: "./assests/cosmetics/CC Cream.png" },
  { image: "./assests/Disposable/16.png" },
  { image: "./assests/Disposable/Ice.png" },
];
export const paper = [
  { image: "./assests/Paper/1.png" },
  { image: "./assests/Paper/2.png" },
  { image: "./assests/Paper/5.png" },
  { image: "./assests/Paper/9.png" },
  { image: "./assests/Paper/Cotton paper.png" },
  { image: "./assests/Paper/7.png" },
  { image: "./assests/Paper/8.png" },
  { image: "./assests/Paper/Kraft paper.png" },
];
export const machine = [
  { image: "./assests/machine/3.png" },
  { image: "./assests/machine/4.png" },
  { image: "./assests/machine/10.png" },
  { image: "./assests/machine/11.png" },
  { image: "./assests/machine/12.png" },
  { image: "./assests/machine/13.png" },
  { image: "./assests/machine/14.png" },
  { image: "./assests/machine/15.png" },
];

export const product = [
  {
    id: "1",
    title: "Cosmetics",
    para:
      "Beauty products enhance personal care and grooming by nourishing the skin, hair, and body. They include cosmetics, skincare, fragrances, and hair care items tailored to various needs. With a focus on health and appearance, these products boost confidence and promote self-expression. Quality beauty products often combine natural ingredients with scientific advancements for effective results.",
    images: [
      "../assests/cosmetics/CC Cream.png",
      "../assests/cosmetics/Eye shadow.png",
      "../assests/cosmetics/Eyeliner.png",
      "../assests/cosmetics/Lip balm.png",
      "../assests/cosmetics/Lipstick.png",
      "../assests/cosmetics/Mascara.png",
      "../assests/cosmetics/Moisturizer.png",
      "../assests/cosmetics/Primer.png",
      "../assests/cosmetics/Setting Spray.png",
      "../assests/cosmetics/Foundation1.png",
      "../assests/cosmetics/Blush.png",
      "../assests/cosmetics/Brownzer.png",
      "../assests/cosmetics/Concealer.png",
      "../assests/cosmetics/Colorcorrector.png",
      "../assests/cosmetics/Compact.png",
      "../assests/cosmetics/Highlighter 2.png",
      "../assests/cosmetics/Face powder.png",
      "../assests/cosmetics/Foundation.png",
      "../assests/cosmetics/Gel eyelinear.png",
      "../assests/cosmetics/Highlighter.png",
      "../assests/cosmetics/Liner1.png",
      "../assests/cosmetics/Lip gloss.png",
      "../assests/cosmetics/Scrab.png",
      "../assests/cosmetics/p1.png",
    ],
  },
  {
    id: "2",
    title: "Paper",
    para:
      "Paper products play a vital role in daily life, ranging from notebooks, tissues, and packaging materials to office supplies. They are made from renewable resources, with many options now focusing on eco-friendly and recycled materials. These products support communication, hygiene, and storage needs across industries. Their versatility makes them essential in homes, schools, and workplaces worldwide.",
    images: [
      "../assests/Paper/1.png",
      "../assests/Paper/2.png",
      "../assests/Paper/5.png",
      "../assests/Paper/6.png",
      "../assests/Paper/7.png",
      "../assests/Paper/8.png",
      "../assests/Paper/9.png",
      "../assests/Paper/16.png",
      "../assests/Paper/18.png",
      "../assests/Paper/Carbon paper.png",
      "../assests/Paper/Carbonless.png",
      "../assests/Paper/Chalk Paper.png",
      "../assests/Paper/Construction paper.png",
      "../assests/Paper/Cotton paper.png",
      "../assests/Paper/Cover Stock.png",
      "../assests/Paper/Graph paper.png",
      "../assests/Paper/Kraft paper.png",
      "../assests/Paper/Printing paper.png",
      "../assests/Paper/Rice paper.png",
      "../assests/Paper/Stickers.png",
      "../assests/Paper/Tag Paper.png",
      "../assests/Paper/Tissue paper.png",
      "../assests/Paper/Tracing paper.png",
      "../assests/Paper/Waxed paper.png",
      "../assests/Paper/17.png",
    ],
  },
  // {
  //   id: "3",
  //   title: "Stationary",
  //   para:
  //     "Stationery products include essential items like pens, notebooks, rulers, and adhesives used for writing, organizing, and crafting. They are widely utilized in schools, offices, and homes to support productivity and creativity. With a variety of designs and functions, stationery caters to both practical needs and personal expression. High-quality and innovative stationery can enhance efficiency and add charm to everyday tasks.",
  //   images: [
  //     "../assests/Stationery/22.png",
  //     "../assests/Stationery/board pens.png",
  //     "../assests/Stationery/Clip.png",
  //     "../assests/Stationery/Cuter.png",
  //     "../assests/Stationery/Erasers.png",
  //     "../assests/Stationery/Highlighters.png",
  //     "../assests/Stationery/Pencils.png",
  //     "../assests/Stationery/Pens.png",
  //     "../assests/Stationery/pin.png",
  //     "../assests/Stationery/Rubber.png",
  //     "../assests/Stationery/Stapler.png",
  //     "../assests/Stationery/sticky notes.png",
  //     "../assests/Stationery/Taps.png",
  //     "../assests/Stationery/Writing Paper.png",
  //   ],
  // },
  {
    id: "4",
    title: "Disposable",
    para:
      "Disposable products are designed for single-use to provide convenience and maintain hygiene across various settings. They include items like plates, cups, gloves, masks, and packaging materials used in homes, healthcare, and hospitality industries. These products help reduce the spread of contamination but also contribute to waste, prompting a shift towards biodegradable and eco-friendly alternatives. Disposables offer practicality, especially for events and medical purposes, where quick disposal is essential.",
    images: [
      "../assests/Disposable/19.png",
      "../assests/Disposable/20.png",
      "../assests/Disposable/21.png",
      "../assests/Disposable/22.png",
      "../assests/Disposable/23.png",
      "../assests/Disposable/24.png",
      "../assests/Disposable/3.png",
      "../assests/Disposable/7.png",
      "../assests/Disposable/8.png",
      "../assests/Disposable/9.png",
      "../assests/Disposable/10.png",
      "../assests/Disposable/11.png",
      "../assests/Disposable/12.png",
      "../assests/Disposable/13.png",
      "../assests/Disposable/14.png",
      "../assests/Disposable/15.png",
      "../assests/Disposable/16.png",
      "../assests/Disposable/17.png",
      "../assests/Disposable/18.png",
      "../assests/Disposable/Glass (2).png",
      "../assests/Disposable/Glass.png",
      "../assests/Disposable/Ice.png",
      "../assests/Disposable/Plates.png",
      "../assests/Disposable/Tea.png",
    ],
  },
  // {
  //   id: "5",
  //   title: "Garments",
  //   para:
  //     "Garments are clothing items that serve both functional and aesthetic purposes, providing comfort, protection, and style. They come in a variety of materials, designs, and categories, including casual wear, formal attire, and sportswear. The garment industry plays a crucial role in fashion trends, personal expression, and cultural identity. With increasing awareness, there is a growing demand for sustainable and eco-friendly clothing options.",
  //   images: [
  //     "../assests/Garments/1.png",
  //     "../assests/Garments/5.png",
  //     "../assests/Garments/6.png",
  //     "../assests/Garments/7.png",
  //     "../assests/Garments/8.png",
  //     "../assests/Garments/9.png",
  //     "../assests/Garments/10.png",
  //     "../assests/Garments/17.png",
  //     "../assests/Garments/Nightwear,.png",
  //     "../assests/Garments/rompers.png",
  //     "../assests/Garments/c9.png",
  //     "../assests/Garments/c12.png",
  //   ],
  // },
];
