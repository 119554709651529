import React, { useState } from "react";
import "../selectDrop/selectdrop.css";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

const SelectDrop = props => {
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState(props.placeholder);

  const openSelect = () => {
    setIsOpenSelect(!isOpenSelect);
  };
  const closeSelect = (index, name) => {
    setSelectedIndex(index);
    setIsOpenSelect(false);
    setSelectedItem(name);
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setIsOpenSelect(false);
      }}
    >
      <div className="selectDrop cursor ">
        {props.icon}
        <span className="openSelect" onClick={openSelect}>
          {selectedItem}{" "}
        </span>
        {isOpenSelect === true && (
          <div className="select">
            {/* <div className='searchfield'>
<input type='text' placeholder='Search here...'/>

      </div> */}
            <ul className="searchResults">
              <li
                key={0}
                onClick={() => closeSelect(0, props.placeholder)}
                className={`${selectedIndex === 0 ? "active" : ""}`}
              >
                {props.placeholder}
              </li>
              {props.data.map((item, index) => {
                return (
                  <li
                    key={index + 1}
                    onClick={() => closeSelect(index + 1, item)}
                    className={`${selectedIndex === index + 1 ? "active" : ""}`}
                  >
                    {item}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default SelectDrop;
