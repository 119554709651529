import Aos from "aos";
import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import ScrollTrigger from "react-scroll-trigger";
import Layout from "../Components/Layout";
import WhatsappButton from "../Components/WhatsappButton";
import { paper, other, machine } from "../Data/productsApi";
import "aos/dist/aos.css";

const Home = () => {
  useEffect(() => {
    Aos.init({ duration: 2000, once: false });
  }, []);
  const [counterOn, setCounteron] = useState(false);
  return (
    <Layout>
      <WhatsappButton />

      {/* //Slider start */}

      <div className="video-container">
  <video autoPlay loop muted playsInline className="w-100">
    <source src="./assests/paper.mp4" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</div>


      {/* end carousel */}

      {/* paper product  map*/}
      <div className="container">
        <div className="row">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 my-4 bg-light p-5">
              <div data-aos="fade-right">
                <h3>High Quality Paper Solutions for Every Need</h3>
                <p>
                  &#x2022; We provide a diverse range of high-quality paper
                  products, including Kraft, coated, carbonless, and packaging
                  paper, tailored for business and personal needs.<br />
                  &#x2022; Explore our{" "}
                  <b>
                    <Link
                      to="/paper/2"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Paper
                    </Link>
                  </b>{" "}
                  products,{" "}
                  <b>
                    <Link
                      to="/disposable/4"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Disposable
                    </Link>
                  </b>{" "}
                  and{" "}
                  <b>
                    <Link
                      to="/cosmetics/1"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Cosmetics
                    </Link>
                  </b>{" "}
                  for all your needs.<br />
                  &#x2022; From offset printing to thermal and disposable paper,
                  we offer reliable solutions for commercial and industrial use.<br
                  />
                  &#x2022; Whether for printing, writing, or packaging, our
                  paper solutions ensure quality and durability.
                </p>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12 my-4">
              <div data-aos="zoom-in">
                <h1 className=" text-center heading">Top Selling Items </h1>
              </div>
              <div className="row">
                {paper.map((ele, index) => (
                  <div key={index} className="col-lg-3 col-md-6 col-sm-12 my-4">
                    <div
                      className="custom-card card"
                      id="card"
                      style={{ width: "100%" }}
                    >
                      <img
                        src={ele.image}
                        className="custom-card-img-top card-img-top"
                        alt="Course Preview"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end map */}

      {/* machine  map*/}

      <div className="container ">
        <div className="row">
          <div data-aos="zoom-in">
            <h1 className="heading text-center">
              Advanced Machinery for Paper Production
            </h1>
          </div>
          {machine.map((ele, index) => (
            <div key={index} className="col-lg-3 col-md-6 col-sm-12 my-4">
              <div data-aos="zoom-in">
                <div
                  className="custom-card card"
                  id="card"
                  style={{ width: "100%" }}
                >
                  <img
                    src={ele.image}
                    className="custom-card-img-top card-img-top"
                    alt="Course Preview"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* end map */}

      {/* other images map */}

      <div className="container">
        <div className="row">
          <div data-aos="zoom-in">
            <h1 className="text-center heading">Other Products </h1>
          </div>
          {other.map((ele, index) => (
            <div key={index} className="col-lg-3 col-md-6 col-sm-12 my-4">
              <div data-aos="zoom-in">
                <div
                  className="custom-card card"
                  id="card"
                  style={{ width: "100%" }}
                >
                  <img
                    src={ele.image}
                    className="custom-card-img-top card-img-top"
                    alt="Course Preview"
                  />
                </div>
              </div>
            </div>
          ))}
          <div class="ml-auto align-self-center text-center mt-2">
            <Link
              class="btn btn-info-gradiant rounded-pill btn-md text-light"
              id="btn"
              to="/cosmetics/1"
            >
              <span>Show more</span>
            </Link>
          </div>
        </div>
      </div>
      {/* end map  */}

      {/* COUNTER ANIMATION START HERE */}
      <div className="container">
        <div className="row mt-5 ">
          <div className="col-lg-3">
            <ScrollTrigger
              onEnter={() => setCounteron(true)}
              onExit={() => setCounteron(false)}
            >
              <div
                className="counter"
                style={{ textAlign: "center", marginBottom: "20px" }}
              >
                <h3 style={{ fontSize: "2em", color: " #830570" }}>
                  {counterOn && (
                    <CountUp start={0} end={200} duration={2} delay={0} />
                  )}
                </h3>
                <p
                  style={{
                    fontSize: "1.2em",
                    color: "#000000",
                    fontWeight: "bolder",
                  }}
                >
                  Projects Done
                </p>
              </div>
            </ScrollTrigger>
          </div>

          <div className="col-lg-3">
            <ScrollTrigger
              onEnter={() => setCounteron(true)}
              onExit={() => setCounteron(false)}
            >
              <div
                className="counter"
                style={{ textAlign: "center", marginBottom: "20px" }}
              >
                <h3 style={{ fontSize: "2em", color: " #830570" }}>
                  {counterOn && (
                    <CountUp start={0} end={550} duration={2} delay={0} />
                  )}
                </h3>
                <p
                  style={{
                    fontSize: "1.2em",
                    color: "#000000",
                    fontWeight: "bolder",
                  }}
                >
                  Clients Happy
                </p>
              </div>
            </ScrollTrigger>
          </div>

          <div className="col-lg-3">
            <ScrollTrigger
              onEnter={() => setCounteron(true)}
              onExit={() => setCounteron(false)}
            >
              <div
                className="counter"
                style={{ textAlign: "center", marginBottom: "20px" }}
              >
                <h3 style={{ fontSize: "2em", color: " #830570" }}>
                  {counterOn && (
                    <CountUp start={0} end={50} duration={2} delay={0} />
                  )}
                </h3>
                <p
                  style={{
                    fontSize: "1.2em",
                    color: "#000000",
                    fontWeight: "bolder",
                  }}
                >
                  Awards
                </p>
              </div>
            </ScrollTrigger>
          </div>

          <div className="col-lg-3">
            <ScrollTrigger
              onEnter={() => setCounteron(true)}
              onExit={() => setCounteron(false)}
            >
              <div
                className="counter"
                style={{ textAlign: "center", marginBottom: "20px" }}
              >
                <h3 style={{ fontSize: "2em", color: " #830570" }}>
                  {counterOn && (
                    <CountUp start={0} end={300} duration={2} delay={0} />
                  )}
                </h3>
                <p
                  style={{
                    fontSize: "1.2em",
                    color: "#000000",
                    fontWeight: "bolder",
                  }}
                >
                  Employees
                </p>
              </div>
            </ScrollTrigger>
          </div>
        </div>
      </div>
      {/* COUNTER ANIMATION END HERE */}

      <div className="container">
        <div className="row">
          <div data-aos="zoom-in">
            <h1 className="text-center heading">
              For Customized Production Requirements{" "}
            </h1>
          </div>
          <p className="text-center">
            We have more than 20 years of rich experience in self-adhesive
            products import and export trade experience, committed to providing
            the highest quality labels. Our regorous quality control process
            ensures every roll meets industry standards for performance and
            durability.
          </p>
        </div>
      </div>

      {/* service section start */}
      <div className="container">
        <div class="py-5 service-37 wrap-service37-box">
          <div class="row ">
            <div class="col-lg-5">
              {" "}
              <img
                src="./assests/banner/6.png"
                alt="wrapkit"
                class="img-fluid"
              />{" "}
            </div>
            <div class="col-lg-7 align-self-center">
              <div class="card max-600 border-0">
                <div class="card-body">
                  <h3 class="mb-3 text-uppercase" id="hed">
                    AWESOME EXTRA ORDINARY FLEXIBILITY
                  </h3>
                  <p class="mt-3 text-dark">
                    You can relay on our amazing products list and also our
                    customer services will be greatexperience for you without
                    doubt and in no-time and with great quality for design.
                  </p>
                  <div class="card card-shadow border-0 mb-3">
                    <div class="card-body">
                      <div class="d-block d-md-flex p-2">
                        <div class="mr-3 align-self-center d-none d-md-block">
                          <span class="text-success-gradiant display-5">
                            <i class="icon-cloud-download" />
                          </span>
                        </div>
                        <div class="ml-auto align-self-center">
                          <Link
                            class="btn btn-info-gradiant rounded-pill btn-md"
                            id="btn"
                            to="/paper/2"
                          >
                            <span>Shop now</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
